import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import data from "../data/tools.json";
import * as styles from "../styles/tools.module.css";

const Tools = () => {
  const [tags, setTags] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const ShowMorePosts = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 700);
    };
    ShowMorePosts();
  }, []);
  const updateTags = e => {
    if (tags?.toLowerCase() == e.target.innerText.toLowerCase()) {
      console.log(e.target.innerText);
      console.log(data.length);
      setTags("");
    } else if (e.target.innerText != "all") {
      setTags(e.target.innerText.toLowerCase());
      console.log(e.target.innerText.toLowerCase());
    } else {
      setTags("");
    }
  };
  return (
    <Layout>
      <Helmet>
        <title>Webdevolution | Dev Tools & Resources </title>
        <meta name="title" content="Webdevolution | Dev Tools & Resources" />
        <meta
          name="description"
          content="Collection of free high quality tools and resources from all over the internet. For developers and designers."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.webdevolution.com/tools" />
        <meta
          property="og:title"
          content="Webdevolution | Dev Tools & Resources"
        />
        <meta
          property="og:description"
          content="Collection of free high quality tools and resources from all over the internet. For developers and designers."
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/GVSMzVG/webdevolution-cover.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta
          property="twitter:title"
          content="Webdevolution | Dev Tools & Resources"
        />
        <meta
          property="twitter:description"
          content="Collection of free high quality tools and resources from all over the internet. For developers and designers."
        />
        <meta
          property="twitter:image"
          content="https://i.ibb.co/GVSMzVG/webdevolution-cover.png"
        ></meta>
      </Helmet>
      <div className={styles.mainContainer}>
        {!loading ? (
          <div className={styles.tagsContainer}>
            <span
              className={`${styles.tag} ${
                tags == "" || tags == null ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              all
            </span>
            <span
              className={`${styles.tag} ${
                tags == "color" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              color
            </span>
            <span
              className={`${styles.tag} ${
                tags == "design" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              design
            </span>
            <span
              className={`${styles.tag} ${
                tags == "gradient" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              gradient
            </span>
            <span
              className={`${styles.tag} ${
                tags == "icon" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              icon
            </span>
            <span
              className={`${styles.tag} ${
                tags == "illustration" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              illustration
            </span>
            <span
              className={`${styles.tag} ${
                tags == "api" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              API
            </span>
            <span
              className={`${styles.tag} ${
                tags == "svg" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              SVG
            </span>
            <span
              className={`${styles.tag} ${
                tags == "css" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              CSS
            </span>
            <span
              className={`${styles.tag} ${
                tags == "background" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              background
            </span>
            <span
              className={`${styles.tag} ${
                tags == "code" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              code
            </span>
            <span
              className={`${styles.tag} ${
                tags == "hosting" ? styles.active : null
              }`}
              onClick={e => updateTags(e)}
            >
              hosting
            </span>
            <span
              className={`${styles.tag} ${tags == "ui" ? styles.active : null}`}
              onClick={e => updateTags(e)}
            >
              UI
            </span>
          </div>
        ) : null}

        {loading == false ? (
          <div className={styles.cardsContainer}>
            {tags?.length > 0
              ? data
                  .filter(i => i.tags.find(s => s == tags))
                  .map(i => {
                    return (
                      <a href={i.url}>
                        <div className={styles.devToolCard}>
                          <img src={i.image} alt="" />
                          <div className={styles.devToolCardInfo}>
                            <h2 className={styles.devToolCardInfoHeader}>
                              {i.tags.includes("ad") ? (
                                <span className={styles.adMarker}>Ad</span>
                              ) : null}
                              {i.name}
                            </h2>
                            <p className={styles.devToolCardInfoText}>
                              {i.description}
                            </p>
                          </div>
                        </div>
                      </a>
                    );
                  })
              : data.map(i => {
                  return (
                    <a href={i.url}>
                      <div className={styles.devToolCard}>
                        <img src={i.image} alt="" />
                        <div className={styles.devToolCardInfo}>
                          <h2 className={styles.devToolCardInfoHeader}>
                            {i.tags.includes("ad") ? (
                              <span className={styles.adMarker}>Ad</span>
                            ) : null}
                            {i.name}
                          </h2>
                          <p className={styles.devToolCardInfoText}>
                            {i.description}
                          </p>
                        </div>
                      </div>
                    </a>
                  );
                })}
          </div>
        ) : (
          <div className={styles.ldsEllipsis}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Tools;
