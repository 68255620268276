// extracted by mini-css-extract-plugin
export var mainContainer = "tools-module--mainContainer--E74rc";
export var devToolCard = "tools-module--devToolCard--KNsLC";
export var devToolCardInfo = "tools-module--devToolCardInfo--w6cxN";
export var devToolCardInfoHeader = "tools-module--devToolCardInfoHeader--WEVz0";
export var devToolCardInfoText = "tools-module--devToolCardInfoText--MyryQ";
export var cardsContainer = "tools-module--cardsContainer--KrMEw";
export var fadeIn = "tools-module--fadeIn--Rls6z";
export var tagsContainer = "tools-module--tagsContainer---su+-";
export var tag = "tools-module--tag--Mz184";
export var adMarker = "tools-module--adMarker--dbdER";
export var active = "tools-module--active--NBCNs";
export var ldsEllipsis = "tools-module--ldsEllipsis--2Nb48";
export var ldsEllipsis1 = "tools-module--ldsEllipsis1--AXB+V";
export var ldsEllipsis2 = "tools-module--ldsEllipsis2--cMDwV";
export var ldsEllipsis3 = "tools-module--ldsEllipsis3--KBA8+";